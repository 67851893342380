<template>
  <section>
    <h1
      @click="$emit('editable-heading', true)"
      v-if="type == 'h1'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h1>
    <h2
      @click="$emit('editable-heading', true)"
      v-else-if="type == 'h2'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h2>
    <h3
      @click="$emit('editable-heading', true)"
      v-else-if="type == 'h3'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h3>
    <h4
      @click="$emit('editable-heading', true)"
      v-else-if="type == 'h4'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h4>
    <h5
      @click="$emit('editable-heading', true)"
      v-else-if="type == 'h5'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h5>
    <h6
      @click="$emit('editable-heading', true)"
      v-else-if="type == 'h6'"
      class="d-flex"
      :class="typography"
    >
      {{ text }}
    </h6>
  </section>
</template>
<script>
export default {
  name: "heading",
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: "En-tête",
    },
    justify: {
      type: String,
      default: "left",
    },
    display: {
      type: String,
      default: "display-2",
    },
    type: {
      type: String,
      default: "h1",
    },
  },
  computed: {
    typography() {
      let justify = "";
      this.justify == "center"
        ? (justify = "justify-content-center")
        : this.justify == "right"
        ? (justify = "justify-content-end")
        : (justify = "justify-content-start");
      console.log(" heading justify:" + justify);
      return justify;
    },
  },
  mounted() {
    // console.log(this.typography)
  },
};
</script>