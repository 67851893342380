<template>
  <section>
    <p
      @click="$emit('editable-paragraph', true)"
      :class="justify"
      :style="{
        fontSize: font_size + 'em',
        fontWeight: font_weight,
        fontStyle: font_style,
      }"
    >
      {{ text }}
    </p>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "paragraph",
  data() {
    return {};
  },
  methods: {},
  props: {
    text: {
      type: String,
      default: "Ceci est un nouveau paragraphe",
    },
    justify: {
      type: String,
      default: "left",
    },
    font_family: {
      type: String,
      default: "initial",
    },
    font_style: {
      type: String,
      default: "normal",
    },
    font_weight: {
      type: String,
      default: "normal",
    },
    font_size: {
      default: 1,
    },
  },
  computed: {},
};
</script>
<style scoped>
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
</style>